<template>
  <v-container fluid v-if="view.public">
    <message-card>
      <h2>No Public view yet</h2>
    </message-card>
  </v-container>
  <v-container fluid v-else-if="!division.hydrated && false">
    <message-card>
      <h2>Loading</h2>
    </message-card>
  </v-container>
  <v-container fluid v-else>
    <v-row dense justify="center">
      <v-col cols="12">
        <v-expansion-panels
          v-model="panel"
          multiple
        >
          <!-- Distibutions -->
          <v-expansion-panel
            key="distributions"
            id="distributions"
            ripple
          >
            <v-expansion-panel-header color="color1 color1Text--text" class="title">
              Distributions
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <matchup-matrix
                v-if="!division.isKob"
                :matches="division.matches"
                :division="division"
              ></matchup-matrix>
              <kob-matchup-matrix
                v-else
                :matches="division.matches"
                :division="division"
              ></kob-matchup-matrix>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- EXISTING ROUNDS -->
          <v-expansion-panel
            v-for="round in division.days"
            :key="round.number"
            :id="`round${round.number}`"
            style="border-top: 1px solid white !important"
            ripple
          >
            <v-expansion-panel-header color="color1 color1Text--text" class="title">
              {{round.name}}
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card flat>
                <round-settings
                  :round="round"
                  :fromSelect="fromSelections"
                  :divisionId="division.id"
                  :locked="locked"
                ></round-settings>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- NEW ROUND -->
          <v-expansion-panel
            v-if="newRound"
            :id="`round${newRound.number}`"
            style="border-top: 1px solid white !important"
            ripple
          >
            <v-expansion-panel-header color="color1 color1Text--text" class="title" id="newRound">
              {{newRound.name}}
              <template v-slot:actions>
                <v-icon color="color1Text">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card flat>
                <round-settings
                  :round="newRound"
                  :fromSelect="fromSelections"
                  :divisionId="division.id"
                  @round-created="onCancelNewRound"
                  @cancel-new-round="onCancelNewRound"
                  :edit="true"
                ></round-settings>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>

    </v-row>
    <v-row dense v-if="!newRound && !locked">
      <v-col cols="12">
        <v-btn color="color3" text @click.stop="addRound">Add Round {{division.days.length + 1}}</v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="redirectDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>Oops!</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-3 title">
          You haven't created the pools yet click OK to get started.
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="success" text @click.stop="redirectDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>

</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import Day from '@/classes/TournamentDay'
import TeamSelector from '@/classes/TeamSelector'
import { BracketTypes } from '@/classes/BracketTypes'
import Bracket from '@/classes/Bracket'
import RoundSettings from '@/components/Leagues/LeagueRoundSettings.vue'
import MatchupMatrix from '@/components/Leagues/MatchupMatrix.vue'
import max from 'lodash.max'
import { mapGetters } from 'vuex'
const KobMatchupMatrix = () => import('@/components/Leagues/KobMatchupMatrix.vue')

export default {
  mixins: [RouteMixin],
  data () {
    return {
      panel: [],
      edit: false,
      goAdvanced: false,
      newRound: null,
      redirectDialog: false,
      selectedMMs: []
    }
  },
  computed: {
    ...mapGetters(['tournament', 'getDivision', 'view']),
    locked () {
      return this.tournament.locked || this.division.complete
    },
    division () {
      return this.getDivision(this.divisionId)
    },
    bracketTypes () {
      return BracketTypes
    },
    lastPoolRound () {
      const i = max(this.division.days.filter(f => f.poolPlay).map(d => +d.number))
      if (i) return this.division.days.find(d => d.number === i)
      return null
    },
    lastRound () {
      const i = max(this.division.days.map(d => +d.number))
      if (i) return this.division.days.find(d => d.number === i)
      return null
    },
    lastRoundEnd () {
      return this.lastRound && this.lastRound.endTime
    },
    nextRoundNumber () {
      return this.lastRound ? this.lastRound.number + 1 : 1
    },
    fromSelections () {
      return this.division.days.map(d => {
        return {
          id: d.id,
          number: d.number,
          poolPlay: d.poolPlay,
          bracketPlay: d.bracketPlay,
          text: `${d.name} Results`,
          i: null
        }
      })
    },
    matchupMatrix () {
      const matches = this.division.matches
      const rows = this.division.activeTeams.map(t => {
        var obj = {
          name: t.name
        }
        this.division.activeTeams.filter(f => f.id !== t.id).forEach(ot => {
          const n = matches.filter(m => m.isBetweenTeamId([t.id, ot.id])).length
          obj[ot.name] = n
        })
        return obj
      })
      var tot = {}
      rows.forEach(r => {
        for (const key in r) {
          if (!tot[key]) tot[key] = 0
          tot[key] = tot[key] + r[key]
        }
      })
      tot.name = 'Total'
      rows.push(tot)
      return rows
    },
    matchupHeaders () {
      var cols = this.selectedMMs.length > 0 ? this.selectedMMs : this.matchupMatrix
      return [{
        text: 'Team',
        value: 'name',
        fixed: true
      }, ...cols.map(m => {
        return {
          text: m.name,
          value: m.name,
          align: 'center'
        }
      })]
    }
  },
  methods: {
    addRound () {
      const n = this.nextRoundNumber
      console.log(n)
      this.newRound = new Day(this.$VBL, {
        id: 0,
        number: n,
        date: this.lastRound.date,
        checkInTime: this.lastRound.checkInTime,
        playTime: (this.lastPoolRound && this.lastPoolRound.poolPlay && this.lastPoolRound.endTime) || this.lastRound.playTime,
        poolPlay: false,
        bracketPlay: true,
        brackets: [new Bracket(this.$VBL, { type: 'SINGLE_ELIM', props: this.division.dualProp ? [this.division.dualProp] : [] })],
        teamSelectors: [new TeamSelector(this.$VBL, { type: 'ALL', fromStandings: '0-0' })]
      })
      this.$nextTick(() => {
        this.panel = [n]
        setTimeout(() => {
          this.$vuetify.goTo(`#round${n}`, {
            duration: 300,
            offset: 50,
            easing: 'easeInOutCubic'
          })
        }, 500)
      })
    },
    onCancelNewRound () {
      this.newRound = null
    },
    patch (v) {
      const dto = {}
      dto[v] = this.division[v]

      this.division.patch(dto)
    },
    openAll () {
      if (this.division && this.division.days) {
        this.panel = this.division.days.map((m, i) => i)
      }
    },
    goToNewRound () {
      this.$vuetify.goTo('#newRound', {
        duration: 300,
        offset: 50,
        easing: 'easeInOutCubic'
      })
    }
  },
  watch: {
    newRound: function (val) {
      this.panel = [this.division.days.length]
      this.$nextTick(() => {
        setTimeout(this.goToNewRound, 333)
      })
    },
    division: function (val) {
      this.onCancelNewRound()
      const clone = JSON.stringify(this.panel)
      this.$nextTick(() => {
        this.panel = JSON.parse(clone)
      })
    },
    'division.showTeams': function (val) {
      this.patch('showTeams')
    },
    'division.disableRegistration': function (val) {
      this.patch('disableRegistration')
    },
    '$route.params': {
      handler: function (val) {
        console.log(val.replacement)
      },
      deep: true
    }
  },
  components: {
    RoundSettings,
    MatchupMatrix,
    KobMatchupMatrix
  },
  mounted () {
    // this.openAll()
  }
}
</script>

<style scoped>
</style>
