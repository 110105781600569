<template>
    <v-container class="pa-0" fluid>
      <loading-bar v-model="loading"></loading-bar>
      <!-- Settings Switches -->
      <v-row dense class="mb-3">
          <setting-toggle
            :dataId="round.id"
            :disabled="round.locked"
            label="Published"
            propName="published"
            :isRoundProp="true"
            iClass="mr-3 shrink mt-0"
          ></setting-toggle>
          <setting-toggle
            :dataId="round.id"
            :disabled="round.locked"
            label="Hide Match Times"
            propName="hideTimes"
            :isRoundProp="true"
            iClass="mr-3 shrink mt-0"
          ></setting-toggle>
          <setting-toggle
            :dataId="round.id"
            :disabled="round.locked"
            label="Hide Refs"
            propName="noRefs"
            :isRoundProp="true"
            :jProp="true"
            iClass="mr-3 shrink mt-0"
          ></setting-toggle>
          <setting-toggle
            :dataId="round.id"
            :disabled="round.locked"
            label="Hide Seeds"
            propName="noSeeds"
            :isRoundProp="true"
            :jProp="true"
            iClass="mr-3 shrink mt-0"
          ></setting-toggle>
          <setting-toggle
            :dataId="round.id"
            label="Locked"
            propName="locked"
            :isRoundProp="true"
            :disabled="division.complete"
            iClass="mr-3 shrink mt-0"
          ></setting-toggle>
          <setting-toggle
            v-if="round.bracketPlay || tournamentStyle"
            :dataId="round.id"
            :disabled="round.locked || round.poolPlay"
            label="Tournament Style"
            propName="tournamentStyle"
            :isRoundProp="true"
            :jProp="true"
            iClass="mr-3 shrink mt-0"
          ></setting-toggle>
      </v-row>
      <!-- Name & date -->
      <v-row dense>
        <v-col cols="12" md="6">
          <v-text-field label="Round Name" v-model="round.name" :disabled="!edit"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <date-time-picker
            :datetime.sync="round.dtStart"
            label=""
            :disabled="!edit"
            type="date"
          ></date-time-picker>
        </v-col>
      </v-row>
      <v-window
        v-if="round.id > 0 && !tournamentStyle"
        v-model="window"
      >
        <!-- Match Settings -->
        <v-window-item :key="0">
          <v-row dense>
            <template v-if="round.flight && round.flight.settings.length">
              <v-col cols="12" md="6" v-for="(setting) in round.flight.settings" :key="setting.poolsOf">
                <v-card class="elevation-1">
                  <v-toolbar dense :color="edit ? 'color2 color2Text--text' : 'grey lighten-3'" flat>
                    <v-toolbar-title v-if="setting.poolsOf === 0">Match Settings</v-toolbar-title>
                    <v-toolbar-title v-else>Pools of {{setting.poolsOf}}</v-toolbar-title>
                  </v-toolbar>
                  <loading-bar :value="loading"></loading-bar>
                  <v-card-text v-if="getTemplates(setting.poolsOf).length" class="py-0">
                    <v-select
                      :items="getTemplates(setting.poolsOf)"
                      item-text="name"
                      item-value="id"
                      v-model="setting.organizationTemplateId"
                      label="Custom Template"
                      color="color3"
                      item-color="color3"
                      :disabled="!edit"
                    ></v-select>
                  </v-card-text>
                  <match-settings
                    :setting="setting"
                    :edit="edit"
                    :notime="true"
                  ></match-settings>
                </v-card>
              </v-col>
            </template>
            <v-expand-transition>
              <v-col cols="12" class="text-center pt-6 pb-0" v-if="(!round.hasMatches || edit) && !isBracketPlay">
                <v-btn color="color3 color3Text--text" @click.stop="createClick">{{round.hasMatches ? 'Re-' : ''}}Create Schedule</v-btn>
              </v-col>
            </v-expand-transition>
          </v-row>
        </v-window-item>
        <!-- Fill Wizard -->
        <v-window-item :key="1">
          <v-expand-transition>
            <v-col cols="12" class="text-end pt-6 pb-0" v-if="showKob">
              <v-btn
                color="success"
                @click.stop="askKob = true"
                small text
              >KOB/QOB?</v-btn>
            </v-col>
          </v-expand-transition>
          <v-expand-transition>
          <v-row dense v-if="division.isLadder">
            <v-col cols="12" v-if="!division.isFullySeeded" class="text-center">
              <v-alert type="info" :value="true" text prominent>
                For ladder leagues you must seed the teams.
              </v-alert>
              <v-btn color="color3 color3Text--text" @click.stop="goSeedTeams">Seed teams</v-btn>
            </v-col>
            <v-col cols="12" v-else-if="!ladderReady" class="text-center">
              <v-alert type="info" :value="true" text prominent>
                All previous rounds must be locked before we can fill the ladders
              </v-alert>
            </v-col>
            <v-col cols="12" v-else>
              <ladder-league-fill-wizard
                v-if="window === 1"
                :round="round"
                :division="division"
                @cancel="onCancelClick"
                @complete="onFillComplete"
              ></ladder-league-fill-wizard>
            </v-col>
          </v-row>
          </v-expand-transition>
          <v-expand-transition>
          <v-row dense v-if="division.isKob">
            <v-col cols="12" v-if="!division.isFullySeeded" class="text-center">
              <v-alert type="info" :value="true" text prominent>
                For KOB/QOB style leagues you must seed the teams.
              </v-alert>
              <v-btn color="color3 color3Text--text" @click.stop="goSeedTeams">Seed teams</v-btn>
            </v-col>
            <v-col cols="12" v-else>
              <kob-league-fill-wizard
                v-if="window === 1"
                :round="round"
                :division="division"
                @cancel="onCancelClick"
                @complete="onFillComplete"
              ></kob-league-fill-wizard>
            </v-col>
          </v-row>
          </v-expand-transition>
          <v-expand-transition>
            <v-row dense v-if="!division.isKob && !division.isLadder">
              <v-col cols="12">
                <league-fill-wizard
                  :round="round"
                  :division="division"
                  @cancel="onCancelClick"
                  @complete="onFillComplete"
                ></league-fill-wizard>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-window-item>
      </v-window>

      <template v-if="isBracketPlay || !round.id || tournamentStyle">
        <!-- TEAM SELECTION -->
        <v-card flat tile>
          <v-toolbar dense :color="edit ? 'color2 color2Text--text' : 'grey lighten-3'" flat>
            <v-toolbar-title>Teams</v-toolbar-title>
          </v-toolbar>
          <template
            v-for="(selector, i) in round.teamSelectors"
          >
            <v-row dense justify="center"
              :key="`plus_${i}`"
              v-if="i > 0"
            >
              <v-col class="text-center" cols="12" >
                <v-icon>fas fa-plus</v-icon>
              </v-col>
            </v-row>
            <team-selection
              :key="`selector_${i}`"
              :ref="`selector_${i}`"
              :selector="selector"
              :edit="edit"
              :round="round"
              :fromSelect="fromSelections"
              :noEdit="round.poolPlay && !!roundCreated"
              :divisionId="divisionId"
            ></team-selection>
          </template>
        </v-card>
        <v-row dense justify="center" v-if="edit">
          <v-btn color="color3" text @click.stop="addSelector">Add More Teams</v-btn>
        </v-row>
        <!-- PLAY STYLE -->
        <v-row dense v-if="!round.id || tournamentStyle">
          <v-col cols="12" sm="6">
            <v-radio-group v-model="playType" row :disabled="!edit" hide-details>
              <v-radio label="Pool Play" value="pool" color="color3"></v-radio>
              <v-radio label="Bracket Play" value="bracket" color="color3"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <!-- BRACKET -->
        <v-card flat tile v-if="isBracketPlay">
          <v-toolbar dense :color="edit ? 'color2 color2Text--text' : 'grey lighten-3'" flat>
            <v-toolbar-title>Bracket</v-toolbar-title>
          </v-toolbar>
          <v-row dense>
            <v-col cols="12">
              <bracket-settings
                :round="round"
                :edit="edit"
                :pad="true"
                :division="division"
              ></bracket-settings>
            </v-col>
          </v-row>
        </v-card>

        <!-- POOL PLAY -->
        <v-row dense v-if="isPoolPlay && round.id > 0">
          <v-alert type="info" :value="!round.teams.length" text>
            There are no teams for this division yet.
          </v-alert>
          <template v-if="!round.flights.length">
            <v-col cols="12" v-if="!locked">
              <v-btn
                color="color3 color3Text--text"
                class="mr-2"
                @click.stop="goSeedTeams"
                v-if="round.hasRegSelector && !round.isFullySeeded"
                :disabled="edit"
              >Seed Teams</v-btn>
              <pool-wizard-dialog
                :round="round"
                text="Create Pools"
                :disabled="blockPoolCreate || edit"
              ></pool-wizard-dialog>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12">
              <flight-settings
                :flight="round.flights[0]"
                :title="'Pool Play'"
                :edit="edit"
                :teamCount="round.teamCount"
              ></flight-settings>
            </v-col>
            <v-col cols="12" v-if="edit && !round.freePlay">
              <pool-wizard-dialog :round="round" text="Re-Create Pools" :disabled="round.teams.length === 0"></pool-wizard-dialog>
            </v-col>
          </template>
        </v-row>
      </template>

      <!-- EDIT BUTTONS -->
      <v-row dense justify="end" v-if="!locked && window === 0">
        <v-fab-transition>
          <v-btn
            color="color3 color3Text--text"
            key="save"
            v-if="edit && dirty"
            fab
            small
            class="my-0 mr-1"
            @click.stop="save"
            :loading="saving"
            :disabled="selectorsInvalid"
          >
            <v-icon small>fas fa-save</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            color="color3--text"
            key="save"
            v-if="edit"
            fab
            small
            class="my-0 mr-1"
            @click.stop="dialog = true"
            :loading="saving"
          >
            <v-icon small>fas fa-trash</v-icon>
          </v-btn>
        </v-fab-transition>
        <v-fab-transition>
          <v-btn
            :color="activeFab.color"
            :key="activeFab.icon"
            v-model="fab"
            fab
            small
            class="my-0"
            @click.stop="edit ? cancel() : editMode()"
          >
            <v-icon small>{{activeFab.icon}}</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-row>
      <!-- DELETE ROUND DIALOG -->
      <v-dialog
        v-if="edit"
        v-model="dialog"
        max-width="500px"
      >
        <v-card>
          <v-toolbar color="color2 color2Text--text">
            <v-toolbar-title>Delete Round?</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <div class="title">Are you sure?</div>
            Deleting a round can not be undone.
          </v-card-text>
          <v-container>
            <v-row dense justify="center">
              <v-btn
                color="success"
                class="mr-2"
                @click.stop="deleteRound"
                :loading="saving"
                :disabled="false"
              >Yes</v-btn>
              <v-btn color="error" @click.stop="dialog = false">no</v-btn>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="invalidSnack"
        vertical
        color="error"
      >
        Please check the errors above
        <v-btn text color="white" @click.native="invalidSnack = false">Close</v-btn>
      </v-snackbar>

      <vbl-confirm-dialog
        :ask="askKob"
        question="Is this division a KOB/Qob format?"
        @deny="askKob = false"
        @confirm="markKob"
        :loading="loading"
      ></vbl-confirm-dialog>
    </v-container>
</template>

<script>
import TeamSelector from '@/classes/TeamSelector'
import FlightSettings from '@/components/Tournament/RoundSettings/FlightSettings.vue'
import BracketSettings from '@/components/Tournament/RoundSettings/BracketSettings.vue'
import TeamSelection from '@/components/Leagues/TeamSelectionLeague.vue'
import SettingToggle from '@/components/Tournament/QuickSettings/SettingToggle.vue'
import { BracketTypes } from '@/classes/BracketTypes'
import DateTimePicker from '@/components/Utils/DateTimePicker.vue'
import MatchSettings from '@/components/Tournament/RoundSettings/MatchSettings.vue'
import { mapGetters } from 'vuex'
const LeagueFillWizard = () => import('@/components/Leagues/LeagueFillWizard.vue')
const KobLeagueFillWizard = () => import('@/components/Leagues/KobLeagueFillWizard.vue')
const PoolWizardDialog = () => import('@/components/Tournament/RoundSettings/PoolWizard/PoolWizardDialog.vue')
const LadderLeagueFillWizard = () => import('@/components/Leagues/LadderLeagueFillWizard.vue')

export default {
  props: ['round', 'deletable', 'fromSelect', 'divisionId', 'locked'],
  data () {
    return {
      editMe: false,
      fab: false,
      saving: false,
      dialog: false,
      loading: false,
      applyToAll: false,
      invalidSnack: false,
      window: 0,
      askKob: false,
      templates: []
    }
  },
  computed: {
    ...mapGetters(['tournament', 'getDivision', 'user', 'host']),
    ladderReady () {
      console.log('here')
      const r = this.round
      if (r.number === 1) return true
      const unlocked = this.division.rounds.filter(f => f.number < r.number && !f.leagueLock)
      return unlocked.length === 0
    },
    showKob () {
      return !this.isBracketPlay && this.division.numOfPlayers === 1 && !this.division.isKob && !this.division.isLadder
    },
    selectorsInvalid () {
      return !!this.round.teamSelectors.filter(f => f.type === 'MAPPED' && !f.map).length
    },
    selectorsInvalids () {
      return this.round.teamSelectors.filter(f => f.type === 'MAPPED' && !f.map)
    },
    division () {
      return this.getDivision(this.divisionId)
    },
    roundProps () {
      return this.tournament.jProps && this.tournament.jProps.rounds && this.tournament.jProps.rounds[this.round.id]
    },
    tournamentStyle () {
      return this.roundProps && this.roundProps.tournamentStyle
    },
    roundCreated () {
      return (this.round.poolPlay && this.round.pools.length) || (this.round.bracketPlay && this.round.published)
    },
    activeFab () {
      return this.edit ? { color: 'red white--text', icon: 'fas fa-times' } : { color: 'color3Text color3--text', icon: 'fas fa-pencil-alt' }
    },
    dirty () {
      return this.round.isDirty()
    },
    fromSelections () {
      return this.fromSelect.filter(f => f.number !== this.round.number)
    },
    playType: {
      get () {
        return this.round.poolPlay ? 'pool' : 'bracket'
      },
      set (val) {
        if (val) {
          if (val === 'pool') {
            this.round.toPoolPlay()
          } else {
            this.round.toBracketPlay()
          }
        }
      }
    },
    isPoolPlay () {
      return this.round.poolPlay
    },
    isBracketPlay () {
      return !this.round.poolPlay && this.round.bracketPlay
    },
    edit () {
      return this.round.id === 0 || this.editMe
    },
    bracketTypes () {
      return BracketTypes
    },
    teamCount () {
      return this.round.teamCount || this.round.teams.length
    },
    blockPoolCreate () {
      return this.teamCount < 3 || (this.round.hasRegSelector && !this.round.isFullySeeded)
    },
    divisionStandings () {
      return this.division.standings && this.division.standings
    },
    divisionStandingGroups () {
      return this.divisionStandings && this.divisionStandings.groupTeamsIds
    }
  },
  methods: {
    onFillComplete () {
      this.window = 0
    },
    onCancelClick () {
      this.window = 0
    },
    goSeedTeams () {
      this.$router.push({ name: 'league-division-teams', params: { seed: true } })
    },
    editMode () {
      this.round.edit()
      this.editMe = true
    },
    cancel () {
      if (this.round.id === 0) {
        this.$emit('cancel-new-round')
        return
      }
      this.round.restore()
      this.editMe = false
    },
    hasErrors () {
      let invalid = false
      for (let i = 0; i < this.round.teamSelectors.length; i++) {
        const ref = this.$refs[`selector_${i}`]
        if (ref && ref[0].hasErrors()) invalid = true
      }
      this.invalidSnack = invalid
      return invalid
    },
    save () {
      if (this.dirty) {
        if (this.hasErrors()) return
        this.saving = true
        console.log(this.round.dto)

        if (this.round.id === 0) {
          this.$VBL.post.rounds(this.round.dto, this.divisionId, this.applyToAll)
            .then(() => {
              this.editMe = false
              this.$emit('round-created')
            })
            .catch(err => {
              console.log(err.response)
            })
            .finally(() => {
              this.saving = false
            })
        } else {
          this.$VBL.patch.day(this.round.dto, this.divisionId)
            .then(() => {
              this.editMe = false
            })
            .catch(err => {
              console.log(err.response)
            })
            .finally(() => {
              this.saving = false
            })
        }
      }
    },
    getTemplates (n) {
      const a = this.templates.filter(f => f.type === 'Pool' && f.template.poolsOf === n)
      if (a.length) a.unshift({ name: 'None', id: null })
      return a
    },
    loadTemplates () {
      this.loading = true
      this.$VBL.organization.templates.get(this.host, 'pool')
        .then(r => {
          this.templates = r.data
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.loading = false
        })
    },
    init () {
      if (this.round.teamSelectors.length === 0) {
        this.addSelector()
      }
    },
    addSelector () {
      this.round.teamSelectors.push(new TeamSelector(this.$VBL, null))
    },
    deleteRound () {
      this.saving = true
      this.$VBL.delete.round(this.round.id)
        .then(() => {
          this.dialog = false
          this.editMe = false
        })
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.saving = false
        })
    },
    togglePublish () {
      this.loading = true
      this.round.published = !this.round.published
      this.round.patch({
        published: this.round.published
      })
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    toggleTimes () {
      this.loading = true
      this.round.hideTimes = !this.round.hideTimes
      this.round.patch({
        hideTimes: this.round.hideTimes
      })
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    toggleLocked () {
      this.loading = true
      this.round.locked = !this.round.locked
      this.round.patch({
        locked: this.round.locked
      })
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    toggle (field) {
      this.round[field] = !this.round[field]
      if (!this.round.id) return
      this.loading = true
      const dto = {}
      dto[field] = this.round[field]
      this.round.patch(dto)
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    },
    createClick () {
      if (this.user) {
        this.window = 1
        return
      }
      this.$router.push({ name: 'league-courts', query: { day: this.round._date } })
    },
    markKob () {
      this.loading = true
      this.division.props.push('kob')
      this.division.patch({ props: this.division.props })
        .then(r => { this.askKob = false })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    window: 'cancel',
    round: function (val) {
      this.init()
    },
    'round.teamSelectors.length': function (n, o) {
      if (o && !n) {
        this.init()
      }
    }
  },
  components: {
    FlightSettings,
    BracketSettings,
    TeamSelection,
    DateTimePicker,
    SettingToggle,
    LeagueFillWizard,
    MatchSettings,
    KobLeagueFillWizard,
    PoolWizardDialog,
    LadderLeagueFillWizard
  },
  mounted () {
    this.loadTemplates()
    this.init()
  }
}
</script>

<style>

</style>
