<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        label="Top N Map"
        v-model="selector.advancedN"
        :disabled="disabled"
        :hint="selector.type.includes('NOT') ? 'Enter the number of top teams to skip from each pool seperated by a comma' : 'Enter the number to take from each pool seperated by a comma'"
        persistent-hint
        color="color3"
        readonly
      ></v-text-field>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Advanced Pool Options</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color1 color1Text--text"
          small fab
          @click.stop="dialog= false"
        >
          <v-icon>fas  fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Pool</th>
                <th class="text-left">Court</th>
                <th class="text-center"># of Teams</th>
                <th class="text-left">{{selector.type.includes('NOT') ? 'All but top' : 'Take Top'}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pool, i) in flight.pools" :key="pool.id">
                <td class="py-1">{{ pool.name }}</td>
                <td class="py-1">{{ pool.courts | formatArray }}</td>
                <td class="py-1 text-center">{{ pool.teams.length }}</td>
                <td class="py-1 text-center">
                  <v-text-field
                    :label="selector.type.includes('NOT') ? 'All but top' : 'Take Top'"
                    v-model="take[i]"
                    type="number"
                    color="color3"
                    background-color="grey lighten-3"
                    single-line
                    width="50px"
                    :error-messages="(take[i] < 0 || take[i] > pool.teams.length) ? ['Invalid # of teams'] : []"
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success white--text" small fab @click.stop="save" :disabled="!!invalid">
          <v-icon>fas fa-save</v-icon>
        </v-btn>
        <v-btn color="error white--text" small fab @click.stop="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['selector', 'divisionId', 'disabled'],
  data () {
    return {
      dialog: false,
      take: []
    }
  },
  computed: {
    ...mapGetters(['getDivision']),
    division () {
      return this.getDivision(this.divisionId)
    },
    fromRound () {
      return this.division.days.find(f => f.id === this.selector.fromRoundId)
    },
    flight () {
      return this.fromRound && this.fromRound.flight
    },
    invalid () {
      return this.take.find((t, i) => {
        return t < 0 || t > this.flight.pools[i].teams.length
      })
    }
  },
  methods: {
    save () {
      this.selector.advancedN = this.take.join(',')
      this.dialog = false
    }
  },
  watch: {
    dialog: function (val) {
      if (val && this.selector.advancedN) {
        this.take = this.selector.advancedN.split(',')
      }
    }
  }
}
</script>
