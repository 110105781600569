<template>
  <v-container fluid>
    <!-- TEAM SELECTION -->
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-select
          :items="fromOptions"
          v-model="selectedFrom"
          item-text="text"
          return-object
          label="Get Teams From"
          :disabled="!edit || noEdit"
          color="color3"
          item-color="color3"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          label="Teams Selection"
          v-model="selectedOption"
          :items="poolPullOptions"
          item-text="displayName"
          return-object
          :disabled="!edit || noEdit"
          color="color3"
          item-color="color3"
        ></v-select>
      </v-col>
      <template v-if="selector && selector.type === 'RANGE'">
        <v-col cols="6" sm="3">
          <v-text-field
            label="Starting Seed"
            v-model="selector.startSeed"
            type="number"
            :disabled="!edit || noEdit"
            color="color3"
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="3">
          <v-text-field
            label="Ending Seed"
            v-model="selector.endSeed"
            type="number"
            :disabled="!edit || noEdit"
            color="color3"
          ></v-text-field>
        </v-col>
      </template>
      <v-col cols="12" sm="6" v-if="selector && selector.type === 'SELECT'">
        <v-text-field
          label="Seeds"
          v-model="selector.seeds"
          :disabled="!edit || noEdit"
          hint="Enter the seeds you would like to include seperated by comma"
          persistent-hint
          color="color3"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" v-if="selector && selector.type && selector.type.includes('TOP_N_EACH')">
        <v-text-field
          type="number"
          label="Number of teams"
          v-model="selector.n"
          :disabled="!edit || noEdit"
          :hint="selector.type.includes('NOT') ? `Enter the number of top teams to skip from each ${poolOrGroup}` : `Enter the number of teams to take from each ${poolOrGroup}`"
          persistent-hint
          color="color3"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" v-if="selector && selector.type === 'MAPPED'">
        <v-text-field
          label="Seeding Map"
          v-model="selector.map"
          :disabled="!edit || noEdit"
          :hint="`Enter a seeding map ie: poolNumber, poolFinish | poolNumber, poolFinish`"
          persistent-hint
          color="color3"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" v-if="selector && selector.type && selector.type.includes('TAKE')">
        <top-n-advanced-dialog
          :divisionId="divisionId"
          :selector="selector"
          :disabled="!edit || noEdit"
        ></top-n-advanced-dialog>
      </v-col>
      <v-col cols="12" sm="6" v-if="!selector.type.includes('SPECIFIC')">
        <v-checkbox
          label="Exclude specific teams"
          v-model="selector.exclude"
          :disabled="!edit || noEdit"
          color="color3"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" v-if="selector && selector.type && (selector.type.includes('SPECIFIC') || selector.exclude)">
        <v-autocomplete
          :items="teams"
          item-text="name"
          item-value="id"
          v-model="selectedTeams"
          label="Teams"
          :hint="`Choose teams to ${selector.exclude ? 'exclude' : 'include'}`"
          persistent-hint
          multiple
          chips deletable-chips
          color="color3"
          item-color="color3"
          ref="select2"
          :disabled="!edit || noEdit"
        >
          <v-list-item
            slot="prepend-item"
            ripple
            @click="toggleDivision"
            :color="icon.color"
          >
            <v-list-item-action>
              <v-icon :color="icon.color">{{icon.name}}</v-icon>
            </v-list-item-action>
            <v-list-item-title>Select All</v-list-item-title>
          </v-list-item>
          <v-divider
            slot="prepend-item"
            class="mt-2"
          ></v-divider>
        </v-autocomplete>
      </v-col>
      <div class="caption grey--text" v-if="user && user.vbl">SelectorId: {{selector.id}}</div>
    </v-row>
    <v-expand-transition>
      <v-alert type="error" :value="true" v-if="showErrors && errors.length" text prominent>
        <div v-for="(e, i) in errors" :key="i">{{e}}</div>
      </v-alert>
    </v-expand-transition>
  </v-container>
</template>

<script>
import TeamSelectionOptions from '@/classes/TeamSelectionOptions'
import SeedingStyles from '@/classes/SeedingStyles'
import { mapGetters } from 'vuex'
import TopNAdvancedDialog from '@/components/Tournament/RoundSettings/TopNAdvancedDialog'
import flatten from '@/helpers/ArrayFlatten'

export default {
  props: ['selector', 'edit', 'round', 'fromSelect', 'noEdit', 'divisionId'],
  data () {
    return {
      showErrors: false,
      validMap: false
    }
  },
  computed: {
    ...mapGetters(['getDivision', 'tournament', 'user']),
    teams () {
      if (this.fromRoundId) return this.fromRound && this.fromRound.teams
      return this.division.activeTeams
    },
    selectedTeams: {
      get () {
        return this.selector && this.selector.teamIds ? this.selector.teamIds.split(',').map(m => +m) : []
      },
      set (val) {
        this.selector.teamIds = val.join(',')
      }
    },
    allTeams () {
      return this.teams && this.selectedTeams.length && this.selectedTeams.length === this.teams.length
    },
    someTeams () {
      return this.selectedTeams.length > 0 && !this.allTeams
    },
    icon () {
      if (this.allTeams) return { name: 'fas fa-check-square', color: 'color3' }
      if (this.someTeams) return { name: 'fas fa-minus-square', color: 'color3' }
      return { name: 'far fa-square', color: '' }
    },
    division () {
      return this.getDivision(this.divisionId)
    },
    fromRound () {
      return this.division.days.find(f => f.id === this.selector.fromRoundId)
    },
    fromChallengeBracket () {
      return this.fromRound && this.fromRound.bracket && this.fromRound.bracket.type === 'SINGLE_ELIM_CHALLENGE'
    },
    selectedFrom: {
      get () {
        return this.fromOptions ? this.fromOptions.find(f => f.id === this.selector.fromRoundId && f.i === this.selector.fromStandings) : null
      },
      set (val) {
        if (!val.id && this.selector.fromRoundId && this.selectedOption && this.selectedOption.pool) {
          this.selector.type = 'ALL'
        } else if (val.id && !this.selector.fromRoundId && val.poolPlay && this.selectedOption && this.selectedOption.type === 'ALL') {
          this.selector.type = 'TOP_N_EACH'
        }
        this.selector.fromRoundId = val.id
        this.selector.fromStandings = val.i
        if (this.fromRound && this.fromRound.endTime) {
          this.round.playTime = this.fromRound.endTime
        }
      }
    },
    selectedOption: {
      get () {
        return this.poolPullOptions.find(f => f.type === this.selector.type)
      },
      set (val) {
        this.selector.type = val.type
        this.selector.startSeed = +val.min
        this.selector.endSeed = +val.max
        if (this.selector.type.includes('TAKE') && !this.selector.advancedN && this.fromRound && this.fromRound.flight) {
          this.selector.advancedN = this.fromRound.flight.pools.map(m => 3).join(',')
        }
      }
    },
    selectedSeedStyle: {
      get () {
        return this.seedingStyles.find(f => f.style === this.selector.seedStyle)
      },
      set (val) {
        const wasMapped = this.selector.seedStyle === 'MAPPED'
        this.selector.seedStyle = val.style
        if (val.style === 'MAPPED') {
          this.selector.type = 'MAPPED'
        } else {
          if (wasMapped) this.selector.type = 'TOP_N_EACH'
        }
      }
    },
    poolPullOptions () {
      if (!this.selector.fromRoundId && this.selector.fromStandings === null) return TeamSelectionOptions.filter(f => f.reg)
      return TeamSelectionOptions.filter(f => f.standings)
    },
    poolOrGroup () {
      return this.selector.fromStandings === null ? 'pool' : 'standings group'
    },
    fromOptions () {
      return this.fromSelect ? [...this.standingOptions, { id: null, text: 'Registration', i: null }] : null
    },
    savedStandings () {
      return this.division.standings
    },
    standingOptions () {
      return this.savedStandings ? flatten(this.division.standings.map((s, i) => {
        return s.groups.map((g, i2) => {
          return {
            id: null,
            text: `${s.title}: ${g.name}`,
            i: `${i}-${i2}`
          }
        })
      })) : []
    },
    getSeedStyle () {
      return this.selectedFrom && this.selectedFrom.poolPlay
    },
    seedingStyles () {
      if (this.selector.type === 'SPECIFIC') {
        return SeedingStyles.filter(f => f.style === 'GROUP' || f.style === 'MAPPED')
      }
      return this.selector.seedStyle === 'PRE'
        ? SeedingStyles.filter(f => f.style !== 'ORIGINAL_SEED' && f.style !== 'CAPTURE' && f.style !== 'RESEED')
        : SeedingStyles.filter(f => f.style !== 'ORIGINAL_SEED' && f.style !== 'PRE' && f.style !== 'RESEED')
    },
    errors () {
      const e = []
      if (this.noEdit) return e
      if (!this.selectedSeedStyle && !this.selector.seedStyle) e.push('Please select a Seeding style')
      if (this.selector.type === 'RANGE') {
        !this.selector.startSeed && e.push('Please enter a Starting seed')
        if (+this.selector.endSeed <= +this.selector.startSeed) e.push('Please enter an ending seed > the starting seed')
      }
      if (this.selector.type === 'SELECT') {
        !this.selector.seeds && e.push('Please enter the seeds to use')
      }
      this.selector.type.includes('TOP_N_EACH') && !this.selector.n && e.push('Please enter the number of teams')
      this.selector.type.includes('TAKE') && !this.selector.advancedN && e.push('Please enter the top N map')
      this.selector.type === 'SPECIFIC' && this.selectedTeams.length === 0 && e.push('Please select the teams')
      this.selector.type === 'MAPPED' && !this.validMap && e.push('Your seeding map is invalid')
      return e
    }
  },
  methods: {
    toggleDivision () {
      this.$nextTick(() => {
        if (this.allTeams) {
          this.selector.map = null
        } else {
          this.selector.map = this.fromRound.teams.map(d => d.id).join(',')
        }
        if (this.$refs.select1) this.$refs.select1.blur()
        if (this.$refs.select2) this.$refs.select2.blur()
      })
    },
    setSeedStyle () {
      if (this.selector.type === 'SPECIFIC') {
        this.selector.seedStyle = 'GROUP'
      } else if (this.getSeedStyle && this.selector.seedStyle === 'ORIGINAL_SEED') {
        this.selector.seedStyle = 'CAPTURE'
      } else if (!this.getSeedStyle) {
        this.selector.seedStyle = 'ORIGINAL_SEED'
      }
    },
    hasErrors () {
      if (this.errors.length === 0) return false
      this.showErrors = true
      return true
    }
  },
  watch: {
    getSeedStyle: function (val) {
      this.setSeedStyle()
    },
    fromChallengeBracket: function (val) {
      if (val) {
        this.selector.type = 'RANGE'
      }
    },
    selectedSeedStyle: function (val) {
      if (!val) {
        this.selectedSeedStyle = this.seedingStyles[0]
      }
    }
  },
  components: {
    TopNAdvancedDialog
  },
  mounted () {
    this.setSeedStyle()
  }
}
</script>

<style>

</style>
